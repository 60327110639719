import api from "@/services/apiService"

class ReportService {

  getWaitReward(accountId=null, silent=false) {
    console.log('silent', silent)
    return new Promise((resolve, reject) => {
      api.get(process.env.VUE_APP_API_READ_TICKET + 'report/wait' +(accountId?`/${accountId}`:''), {silent: silent})
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        reject(error)
      })
    })
  }

  getMemberTickets(accountId, roundId=null) {
    return new Promise((resolve, reject) => {
      api.get(process.env.VUE_APP_API_READ_TICKET + 'report/member-wait-ticket/' + accountId + (roundId?`/${roundId}`:''))
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        reject(error)
      })
    })
  }

  /**
   * ดึงรายการแทงของโพย
   */
  getTicket(ticketId) {
    return new Promise((resolve, reject) => {
      api.get(process.env.VUE_APP_API_READ_TICKET + 'report/ticket/' + ticketId)
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        console.log(error)
        reject(error)
      })
    })
  }

  /**
   * ดึงยอดแทงของสมาชิก
   */
  getRoundReportByMember(status, roundId, accountId=null) {
    return new Promise((resolve, reject) => {
      api.get(process.env.VUE_APP_API_READ_TICKET + 'report/by-member/' + status + '/' + roundId + (accountId?`/${accountId}`:''))
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        console.log(error)
        reject(error)
      })
    })
  }

  /**
   * ดึงยอดแทงตามประเภทการแทง
   */
  getRoundReportByType(status, roundId, accountId=null, memberAccountId=null) {
    return new Promise((resolve, reject) => {
      api.get(process.env.VUE_APP_API_READ_TICKET + 'report/by-type/' + status + '/' + roundId + '/' + accountId + (memberAccountId?`/${memberAccountId}`:''))
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        console.log(error)
        reject(error)
      })
    })
  }

  /**
   * ดึงรายการตัวเลขแทง
   */
  getRoundMemberBetNumbers(roundId, accountId=null) {
    return new Promise((resolve, reject) => {
      api.get(process.env.VUE_APP_API_READ_TICKET + 'report/bet-numbers/' + roundId + (accountId?`/${accountId}`:''))
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        console.log(error)
        reject(error)
      })
    })
  }

  /**
   * ดึงรายงานของรวม
   */
  getRoundOverall(roundId) {
    return new Promise((resolve, reject) => {
      api.get(process.env.VUE_APP_API_READ_TICKET + 'report/overall/' + roundId, {silent: true})
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        console.log(error)
        reject(error)
      })
    })
  }

  /**
   * รายงาน แพ้-ชนะ สุทธิ
   */
  getMarketWinLose(params) {
    return new Promise((resolve, reject) => {
      api.post(`${process.env.VUE_APP_API_READ_TICKET}report/market-win-lose/${params.startDate}/${params.endDate}`, {
        accountId: params.accountId,
        marketIds: params.marketIds
      }, {silent: true})
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        console.log(error)
        reject(error)
      })
    })
  }

  getMarketWinLose2(params) {
    return new Promise((resolve, reject) => {
      api.post(`${process.env.VUE_APP_API_READ_TICKET}report/market-win-lose2/${params.startDate}/${params.endDate}`, {
        accountId: params.accountId,
        marketIds: params.marketIds
      }, {silent: true})
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        console.log(error)
        reject(error)
      })
    })
  }

  getMemberWinLose2(params) {
    return new Promise((resolve, reject) => {
      api.post(`${process.env.VUE_APP_API_READ_TICKET}report/member-win-lose2/${params.startDate}/${params.endDate}`, {
        accountId: params.accountId,
        marketIds: params.marketIds
      }, {silent: true})
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        console.log(error)
        reject(error)
      })
    })
  }

  /**
   * รายการ แพ้-ชนะ ของ member
   */
  getMemberWinLoseTickets(params) {
    return new Promise((resolve, reject) => {
      api.post(`${process.env.VUE_APP_API_READ_TICKET}report/member-win-lose/${params.startDate}/${params.endDate}`, {
        accountId: params.accountId,
        marketIds: params.marketIds
      }, {silent: true})
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        console.log(error)
        reject(error)
      })
    })
  }

  /**
   * รายการโพยที่ถูกรางวัล
   */
  getRoundWonTickets(roundId, offset) {
    return new Promise((resolve, reject) => {
      api.get(`${process.env.VUE_APP_API_READ_TICKET}report/win-ticket/${roundId}`+(offset?`/${offset}`:''))
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        console.log(error)
        reject(error)
      })
    })
  }

  /**
   * รายงานการเงิน
   * params: {
   *   accountId: String,
   *   startDate: "YYYY-MM-DD",
   *   endDate: "YYYY-MM-DD"
   * }
   */
  getAccountTransactions(params) {
    return new Promise((resolve, reject) => {
      api.get(`${process.env.VUE_APP_API_MAIN}report/transaction/${params.startDate}/${params.endDate}` + (params?.accountId?`/${params.accountId}`:''))
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        console.log(error)
        reject(error)
      })
    })
  }

  /**
   * ประวัติเข้าใช้งาน
   */
  getAccountLogs(params) {
    return new Promise((resolve, reject) => {
      api.get(`${process.env.VUE_APP_API_MAIN}report/login-logs/${params.startDate}/${params.endDate}` + (params?.accountId?`/${params.accountId}`:''))
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        console.log(error)
        reject(error)
      })
    })
  }

  /**
   * ดึงรายการแทง
   */
  getItems(roundId, data, offset=0) {
    return new Promise((resolve, reject) => {
      api.post(`${process.env.VUE_APP_API_READ_TICKET}report/items/${roundId}`+(offset?`/${offset}`:''), data)
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        console.log(error)
        reject(error)
      })
    })
  }
}

export default new ReportService()
